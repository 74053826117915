import React, {useEffect, useState} from "react";
import Select from 'react-dropdown-select'
import {
    tryGetDataAdCampaign, tryGetDataAdCampaignOptions, tryGetDataAdCreative, tryGetDataAdCreativeOptions,
    tryGetDataAdSource, tryGetDataAdSourceOptions, tryGetTotalData
} from "./table/middleware/getData";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Total from "./table/total";
import styled from "styled-components";
import {Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {colors} from '../components/colors'
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {DateTime} from "luxon";

const TableContainerStyled = styled(TableContainer)`
    .cellName {
      background-color: ${colors.action70};
      font-weight: bold;
    }
  
  .dateRow {
    background-color: ${colors.action70};
  }
  
  .data {
    background-color: ${colors.canvaSand};
  }
  
  .rowName {
    background-color: ${colors.canvaSand};
  }
  
  .activeDate {
    background-color: ${colors.secondary100};
  }
  
  
`
export default function Home() {
    const [optionsAdSource, setOptionsAdSource] = useState([
        {
            value :'Google',
            label:'Google'
        }
    ])
    const [optionsAdCampaign, setOptionsAdCampaign] = useState([])
    const [optionsAdCreative, setOptionsAdCreative] = useState([])
    const [ad, setAdSource] = useState("")
    const [adCampaign, setAdCampaign] = useState('')
    const [adCreative, setAdCreative] = useState('')
    const [currentTotal, setCurrentTotal] = useState({})
    const [currentData, setCurrentData] = useState({})
    const [allTotal, setAllTotal] = useState({})
    const [allData, setAllData] = useState({}) // saves the data in its original form
    const drawerWidth = 240;
    const [dateRange, setDateRange] = useState({
        start : '',
        end : ''
    })
    const [dateType, setDateType] = useState('Daily')
    const [utms, setUtms] = useState({
        utm_content: [''],
        utm_term: [''],
        utm_source: [''],
        utm_medium: [''],
        utm_campaign: ['']
    })
    const [isLoading, setLoading] = useState(true)

    // const [utmOptions, setUtmOptions] = useState({
    //     utm_content: [''],
    //     utm_term: [''],
    //     utm_source: [''],
    //     utm_medium: [''],
    //     utm_campaign: ['']
    // })


    // Total data
    // Ad source options
    useEffect( () => {
        const init = async () => {
            await sortByDateType(dateType)
            setLoading(false)
            const adSourceOptions = await tryGetDataAdSourceOptions()
            const o = []
            for (let d of adSourceOptions.data) {
                o.push({
                    value:d,
                    label:d
                })
            }
            setOptionsAdSource(o)
        }
        init()
    },[])

    // // Ad source updates
    // // Ad campaign options
    useEffect(() => {
        const update = async () => {
            // When ad is updated, calls the api to grab the data for the given ad source
            // Sorts the data by current set date type
            await sortByDateType(dateType)
            // Grabs the Ad source campaign options
            const adCampaignOptions = await tryGetDataAdCampaignOptions(ad)
            const o = []
            for (let d of adCampaignOptions.data) {
                o.push({
                    value:d,
                    label:d
                })
            }
            setOptionsAdCampaign(o)
        }
        update()
    },[ad])
    //
    // // Ad campaign updates
    // // Ad creative options
    useEffect(() => {
        const update = async () => {
            // When campaign is updated, calls the api to grab the data for the given ad campaign
            // Sorts the data by current set date type
            await sortByDateType(dateType)
            const adCreativeOptions = await tryGetDataAdCreativeOptions(adCampaign)
            const o = []
            // Grabs the Ad source creative options
            for (let d of adCreativeOptions.data) {
                o.push({
                    value:d,
                    label:d
                })
            }
            setOptionsAdCreative(o)
        }
        update()
    }, [adCampaign])
    //
    // // Ad creative updates
    useEffect(() => {
        const update = async () => {
            // When creative is updated, calls the api to grab the data for the given ad creative
            // Sorts the data by current set date type
            await sortByDateType(dateType)
        }
        update()
    }, [adCreative])
    //
    // // Date type updates
    // useEffect(() => {
    //     setDateRange({})
    //     sortByDateType(dateType)
    // }, [dateType])
    //
    // // Date range updates
    // useEffect(() => {
    //     setDateType('')
    //     sortByDateRange(dateRange)
    // },[dateRange])
    //
    // // UTM parameters
    // useEffect(() => {
    //     setCurrentData(filterUTM(allData, utms))
    // },[utms])



    // Sorts data by either daily, weekly, or last 6 months
    const sortByDateType = async (type) => {
        const currentDate = DateTime.now().setZone('America/New_York').toISODate()
        let data = { data : {}};
        let total;
        if (type === 'Daily') {
            const sevenDays = DateTime.now().setZone('America/New_York').minus({weeks:1}).toISODate()
            if (ad !== '' && adCampaign === '') {
                data = await tryGetDataAdSource(ad, sevenDays, currentDate, 'Daily')
            }
            else if (adCampaign !== '' && adCreative === '') {
                data = await tryGetDataAdCampaign(adCampaign, sevenDays, currentDate, 'Daily')
            } else if (adCreative !== ''){
                data = await tryGetDataAdCreative(adCreative, sevenDays, currentDate, 'Daily')
            }
            total = await tryGetTotalData(sevenDays, currentDate, 'Daily')
            setCurrentTotal(total.data)

        } else if (type === 'Weekly') {
            const sixWeeks = DateTime.now().setZone('America/New_York').minus({weeks:7}).toISODate()
            if (ad !== '' && adCampaign === '') {
               data = await tryGetDataAdSource(ad, sixWeeks, currentDate, 'Weekly')
            }
            else if (adCampaign !== '' && adCreative === '') {
               data = await tryGetDataAdCampaign(adCampaign, sixWeeks, currentDate, 'Weekly')
            } else if (adCreative !== ''){
               data = await tryGetDataAdCreative(adCreative, sixWeeks, currentDate, 'Weekly')
            }
            total = await tryGetTotalData(sixWeeks, currentDate, 'Weekly')
            setCurrentTotal(total.data)

        } else if (type === 'Last 6 months') {
            const sixMonths = DateTime.now().setZone('America/New_York').minus({months:6}).toISODate()
            if (ad !== '' && adCampaign === '') {
                data = await tryGetDataAdSource(ad, sixMonths, currentDate, 'Monthly')
            }
            else if (adCampaign !== '' && adCreative === '') {
                data = await tryGetDataAdCampaign(adCampaign, sixMonths, currentDate, 'Monthly')
            } else if (adCreative !== ''){
               data = await tryGetDataAdCreative(adCreative, sixMonths, currentDate, 'Monthly')
            }
            total = await tryGetTotalData(sixMonths, currentDate, 'monthly')
            setCurrentTotal(total.data)
        }


        setCurrentData(data.data)
    }



    // Sorts data by a given date range
    const sortByDateRange = async (dateRange) => {
        console.log('daterange', dateRange)
        if (dateRange.start !== '' && dateRange.end !== '') {
            const startDate = dateRange.start
            const lastDate = dateRange.end
            let dateType = 'daily'
            let range = startDate - lastDate;
            let data = {};
            let total;
            if (range <= 7) {
                dateType = 'Daily'
            } else if (range <= 42 && range >= 7) {
                dateType = 'Weekly'
            } else if (range > 42) {
                dateType = 'Monthly'
            }
            if (ad !== '' && adCampaign === '') {
                data =  await tryGetDataAdSource(ad, lastDate, startDate, dateType)
            } else if (adCampaign !== '' && adCreative === '') {
                data = await tryGetDataAdCampaign(adCampaign, lastDate, startDate, dateType)
            } else if (adCreative !== '') {
                data = await tryGetDataAdCreative(adCreative, lastDate, startDate, dateType)
            }
            total = await tryGetTotalData(lastDate, startDate, dateType)
            setCurrentTotal(total.data)
            setCurrentData(data.data)
            setAllTotal(total.data)
            setAllData(data.data)
        }
    }



    // Filter data by utms chosen in the selects
    const filterUTM = (data, utm) => {
        // Filter the data based on the specified parameters
        const filteredData = data.filter(item => {
            return Object.entries(utm).every(([key, value]) => {
                return item[key] === value; // Check if each key matches the value
            });
        });

        return filteredData;

    }
    if (isLoading) {

    } else {
        return (
        <>
            <Box sx={{display: 'flex', width: '100%'}}>
                <CssBaseline/>
                <AppBar position="fixed" sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: colors.primary100
                }}>
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography variant="h6" noWrap component="div">
                            Willow Reporting
                        </Typography>
                        <Typography variant="h6" noWrap component="div">
                            {ad && ` ${ad}`}
                            {adCampaign && ` > ${adCampaign}`}
                            {adCreative && ` > ${adCreative}`}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},

                    }}
                >
                    <Toolbar/>
                    <Box sx={{overflow: 'auto'}}>
                        <List sx={{margin: '0 20px 0 20px'}}>
                            {optionsAdSource.length > 0 &&
                                <>
                                    <h4>Ad Source</h4>
                                    <Select
                                        options={optionsAdSource}
                                        onChange={(value) => setAdSource(value[0].label)}/>
                                </>}                            {optionsAdCampaign.length > 0 &&
                            <>
                                <h4>Ad Campaign</h4>
                                <Select

                                options={optionsAdCampaign}
                                onChange={(value) => setAdCampaign(value[0].label)}  />
                            </>
                            }
                            {optionsAdCreative.length > 0 &&
                                <>
                            <h4>Ad Creative</h4>
                            <Select
                                options={optionsAdCreative}
                                onChange={(value) => setAdCreative(value[0].label)}  />
                            </>}
                            {/*<h4>UTM Medium</h4>*/}
                            {/*<Select*/}
                            {/*    options={utmOptions.utm_medium}*/}
                            {/*    multi*/}
                            {/*    onChange={(value) =>*/}
                            {/*        setUtms({...utms, utm_medium: [...utms.utm_medium, value]})}*/}
                            {/*        />*/}
                            {/*<h4>UTM Content</h4>*/}
                            {/*<Select*/}
                            {/*    options={utmOptions.utm_content}*/}
                            {/*    multi*/}
                            {/*    onChange={(value) =>*/}
                            {/*        setUtms({...utms, utm_medium: [...utms.utm_content, value]})}*/}
                            {/*/>*/}
                            {/*<h4>UTM Term</h4>*/}
                            {/*<Select*/}
                            {/*    options={utmOptions.utm_term}*/}
                            {/*    multi*/}
                            {/*    onChange={(value) =>*/}
                            {/*        setUtms({...utms, utm_medium: [...utms.utm_term, value]})}*/}
                            {/*/>*/}
                            {/*<h4>UTM Source</h4>*/}
                            {/*<Select*/}
                            {/*    options={utmOptions.utm_source}*/}
                            {/*    multi*/}
                            {/*    onChange={(value) =>*/}
                            {/*        setUtms({...utms, utm_medium: [...utms.utm_source, value]})}*/}
                            {/*/>*/}
                        </List>
                        <Divider/>
                        <List>
                            {['Daily', 'Weekly', 'Last 6 Months'].map((text, index) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton onClick={() => setDateType(text)}
                                                    className={text === dateType ? 'activeDate' : ''}>
                                        <ListItemText primary={text}/>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            {/*<LocalizationProvider dateAdapter={AdapterLuxon}>*/}
                            {/*    <DatePicker*/}
                            {/*        label="Controlled picker"*/}
                            {/*        value={dateRange}*/}
                            {/*        onChange={(newValue) => setDateRange({...dateRange, start: newValue})}*/}
                            {/*    />*/}
                            {/*    <DatePicker*/}
                            {/*        label="Controlled picker"*/}
                            {/*        value={dateRange}*/}
                            {/*        onChange={(newValue) => setDateRange({...dateRange, end: newValue })}*/}
                            {/*    />*/}
                            {/*</LocalizationProvider>*/}
                        </List>
                    </Box>
                </Drawer>
                <Box sx={{width: '100%'}}>
                    <Toolbar/>
                   <Total rows={currentTotal}/>
                    {/* "Each Ad Source Data" Tables*/}
                    <TableContainerStyled>

                        { Object.keys(currentData).map((a, index) => {
                            return <Table sx={{minWidth: '100%'}} aria-label="simple table" key={a}>
                                <TableHead>
                                    <TableRow className={'dateRow'}>
                                        <TableRow>
                                            <TableCell className={'cellName'}>{a}</TableCell>
                                        </TableRow>
                                        {currentData[a].map((row, index) => (

                                            <TableCell component="th" scope="row" key={"date" + index}>
                                                {row.created}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left" component="th" scope="row" className={'rowName'}>
                                            Spend
                                        </TableCell>
                                        {currentData[a].map((row, index) => {
                                            return <TableCell key={"spend" + index}>
                                                ${Number(row.spend).toFixed(2)}
                                            </TableCell>
                                        })}

                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" className={'rowName'}>Impressions</TableCell>
                                        {currentData[a].map((row, index) => {
                                            return <TableCell key={"spend" + index}>
                                                {Number(row.impressions).toFixed(2)}
                                            </TableCell>
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" className={'rowName'}>Clicks</TableCell>
                                        {currentData[a].map((row, index) => {
                                            return <TableCell key={"spend" + index}>
                                                {Number(row.clicks).toFixed(2)}
                                            </TableCell>
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" className={'rowName'}>Spend / Impressions</TableCell>
                                        {currentData[a].map((row, index) => {
                                            const spendPerImpressions = (row.spend / row.impressions).toFixed(2)
                                            return <TableCell key={"spend" + index}>
                                                ${spendPerImpressions}
                                            </TableCell>
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" component="th" scope="row" className={'rowName'}>
                                            Spend / Clicks
                                        </TableCell>
                                        {currentData[a].map((row, index) => {
                                            const spendPerClick = (row.spend / row.clicks).toFixed(2)
                                            return <TableCell key={"spend" + index}>
                                                ${spendPerClick}
                                            </TableCell>
                                        })}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" className={'rowName'}># Clicks / #
                                            Impressions</TableCell>
                                        {currentData[a].map((row, index) => {
                                            const clicksToImpressions = ((row.clicks / row.impressions) * 100).toFixed(2)
                                            return <TableCell key={"spend" + index}>
                                                {clicksToImpressions}%
                                            </TableCell>
                                        })}
                                    </TableRow>

                                </TableBody>
                            </Table>
                    })}
                    </TableContainerStyled>
                </Box>
            </Box>
        </>


    )
    }
}