import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import styled from "styled-components";
import {colors} from '../../components/colors'
const TableContainerStyled = styled(TableContainer)`
  .cellName {
    background-color: ${colors.action70};
    font-weight: bold;
  }

  .dateRow {
    background-color: ${colors.action70};
  }
  
  .rowName {
    background-color: ${colors.canvaSand};
  }
`
export default function Total(props) {
    const rows = props.rows
    return <TableContainerStyled component={Paper}>
        {/*  "Total Paid Data" Table       */}
        <Table sx={{minWidth:600}} aria-label="simple table">
            <TableHead>
                <TableRow className={'dateRow'}>
                    <TableCell className={"cellName"}>Total Paid</TableCell>
                    {rows.map((row, index) => (

                        <TableCell component="th" scope="row" key={"date" + index}>
                            {`${new Date(row.created).getMonth() + 1}/${new Date(row.created).getDate()}/${new Date(row.created).getFullYear()}`}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align="left" component="th" scope="row" className={'rowName'}>
                        Spend
                    </TableCell>
                    {rows.map((row, index) => {
                        return <TableCell key={"spend" + index}>
                            ${Number(row.spend).toFixed(2)}
                        </TableCell>
                    })}

                </TableRow>
                <TableRow>
                    <TableCell align="left" className={'rowName'}>Impressions</TableCell>
                    {rows.map((row, index) => {
                        return <TableCell key={"spend" + index}>
                            {Number(row.impressions).toFixed(2)}
                        </TableCell>
                    })}
                </TableRow>
                <TableRow>
                    <TableCell align="left" className={'rowName'}>Clicks</TableCell>
                    {rows.map((row, index) => {
                        return <TableCell key={"spend" + index}>
                            {Number(row.clicks).toFixed(2)}
                        </TableCell>
                    })}
                </TableRow>

                <TableRow>
                    <TableCell align="left" className={'rowName'}>Spend / Impressions</TableCell>
                    {rows.map((row, index) => {
                        const impressionsPerClick = (row.spend / row.impressions).toFixed(2)
                        return <TableCell key={"spend" + index}>
                            {impressionsPerClick}
                        </TableCell>
                    })}
                </TableRow>
                <TableRow>
                    <TableCell align="left" component="th" scope="row" className={'rowName'}>
                        Spend / Clicks
                    </TableCell>
                    {rows.map((row, index) => {
                        const spendPerClick = (row.spend / row.clicks).toFixed(2)
                        return <TableCell key={"spend" + index}>
                            ${spendPerClick}
                        </TableCell>
                    })}
                </TableRow>
                <TableRow>
                    <TableCell align="left" className={'rowName'}># Clicks / # Impressions</TableCell>
                    {rows.map((row, index) => {
                        const clicksToImpressions = ((row.clicks / row.impressions) * 100).toFixed(2)
                        return <TableCell key={"spend" + index}>
                            {clicksToImpressions}%
                        </TableCell>
                    })}
                </TableRow>
            </TableBody>
        </Table>



    </TableContainerStyled>
}