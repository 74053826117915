import {axiosReq} from "../../../utils/axios";

const devData = {
    didError: false,
    data: [
        {
            "id": 1,
            "ad_source": "Organic",
            "spend":0,
            "impressions":0,
            "clicks":0,
            "utm_source":"ise",
            "utm_term": "ise",
            "utm_campaign":"ise",
            "utm_medium":"ise",
            "utm_content":"ise",
            "created":'2024-09-26',
            "raw": {}},
        {
            "id": 2,
            "ad_source": "Organic",
            "spend":0,
            "impressions":0,
            "clicks":0,
            "utm_source":"ise",
            "utm_term": "ise",
            "utm_campaign":"ise",
            "utm_medium":"ise",
            "utm_content":"ise",
            "created":'2024-09-26',
            "raw": {}
        }
    ]
}

const devDailyData = {
    data : [
        {spend: 50, impressions: 1000, clicks: 50, created: '09/27/2024'},
        {spend:100, impressions: 3000, clicks: 100, created: '09/26/2024'},
        {spend: 200, impressions: 2000, clicks: 150, created: '09/25/2024'},
        {spend:100, impressions: 3000, clicks: 100, created: '09/24/2024'},
        {spend: 200, impressions: 2000, clicks: 150, created: '09/23/2024'},
        {spend:100, impressions: 3000, clicks: 100, created: '09/22/2024'},
        {spend: 200, impressions: 2000, clicks: 150, created: '09/21/2024'}
    ]
}

const devAdSourceOptionsData = {
    data :["StackAdapt", "Google"]
}

const devAdCampaignOptionsData = {
    data : ["GFD - Advisors","GFD - Consumer - Competitor","GFD - Consumer - Women", "GFD - Consumer - Branded", "GFD - Consumer - Life Events"]
}

const devAdCreativeOptionsData = {
    data : [
        "Divorce",
        "Women",
    ]
}
const devMonthlyData= { data :

    {
        "161520711743"
    :
        [{"spend": "76", "impressions": "94", "clicks": "6", "created": "09/21/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/22/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/23/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/24/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/25/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/26/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/27/2024"}],
        "162145356187"
    :
        [{"spend": "76", "impressions": "94", "clicks": "6", "created": "09/21/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/22/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/23/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/24/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/25/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/26/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/27/2024"}],
        "166632378186"
    :
        [{"spend": "76", "impressions": "94", "clicks": "6", "created": "09/21/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/22/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/23/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/24/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/25/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/26/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/27/2024"}],

        "156958758217"
    :
        [{"spend": "76", "impressions": "94", "clicks": "6", "created": "09/21/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/22/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/23/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/24/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/25/2024"},
            {"spend": "18", "impressions": "18", "clicks": "1", "created": "09/26/2024"},
            {"spend": "76", "impressions": "94", "clicks": "6", "created": "09/27/2024"}]
    }
};


// returns all impressions,spend, clicks totals for all data
export const tryGetTotalData = (start = '', end = '',dateType = '') => {
    if (start !== '' && end !== '') {
        return axiosReq(`api/ads-total?start=${start}&end=${end}&dateType=${dateType}`, "GET", {},  devDailyData);

    }
    return axiosReq(`api/ads-total`, "GET", {},  devDailyData);
}

// returns all the options for ad sources
export const tryGetDataAdSourceOptions = () => {
    return axiosReq(`api/ad-source-options`, "GET", {},  devAdSourceOptionsData);

}

// returns all the options for the ad campaigns
export const tryGetDataAdCampaignOptions = (ad_source) => {
    return axiosReq(`api/ad-campaign-options?ad_source=${ad_source}`, "GET", {},  devAdCampaignOptionsData);
}

// returns all the options for the ad creatives
export const tryGetDataAdCreativeOptions = (ad_campaign) => {
    return axiosReq(`api/ad-creative-options?ad_campaign=${ad_campaign}`, "GET", {},  devAdCampaignOptionsData);
}

// returns all impressions, spend, clicks totals for a given ad source
export const tryGetDataAdSource = (ad_source, start = '', end = '',dateType = '') => {
    if (start !== '' && end !== '') {
        return axiosReq(`api/data-by-ad-source?ad_source=${ad_source}&start=${start}&end=${end}&dateType=${dateType}`, "GET", {},  devMonthlyData);

    }
    return axiosReq(`api/data-by-ad-source?ad_source=${ad_source}`, "GET", {},  devMonthlyData);
}

export const tryGetDataAdCampaign = (ad_campaign, start = '', end = '',dateType = '') => {
    if (start !== '' && end !== '') {
        return axiosReq(`api/data-by-ad-campaign?ad_campaign=${ad_campaign}&start=${start}&end=${end}&dateType=${dateType}`, "GET", {}, devMonthlyData);
    }
    return axiosReq(`api/data-by-ad-campaign?ad_campaign=${ad_campaign}`, "GET", {}, devMonthlyData);

}

export const tryGetDataAdCreative = (ad_creative, start = '', end = '',dateType = '') => {
    if (start !== '' && end !== '') {
        return axiosReq(`api/data-by-ad-creative?ad_creative=${ad_creative}&start=${start}&end=${end}&dateType=${dateType}`, "GET", {},  devMonthlyData);

    }
    return axiosReq(`api/data-by-ad-creative?ad_creative=${ad_creative}`, "GET", {},  devMonthlyData);
}


